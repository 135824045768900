'use strict';

(function() {
  class ViewBladeCtrl {
    constructor($scope, Auth, $http, $uibModal, ActivityLog, Util, SharedUrl, toastr, $sce) {
      this.$http = $http;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.$uibModal = $uibModal;
      this.Util = Util;
      this.blade = $scope.blade;
      this.bladeFindings = $scope.findings;
      this.toastr = toastr;
      this.bladePositionMap = {
      	'A': 1,
      	'B': 2,
      	'C': 3
      };
      this.ActivityLog = ActivityLog;
      this.SharedUrl = SharedUrl;
      this.sharedLevel = localStorage.getItem('sharedLevel');
      this.$sce = $sce;
      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
        }
      });
      console.log(this.blade);

      this.turbineStr = '';
      if(this.blade.turbine.make) {
        this.turbineStr+=this.blade.turbine.make
      }
      if(this.blade.turbine.model) {
        this.turbineStr+=this.blade.turbine.model
      }
      if(this.blade.turbine.name) {
        this.turbineStr+=this.blade.turbine.name
      }

      this.bladeStr = '';
      if(this.blade.make) {
        this.bladeStr+=this.blade.make+' ';
      }
      if(this.blade.model) {
        this.bladeStr+=this.blade.model;
      }
      if(this.blade.name) {
        this.bladeStr+=this.blade.name;
      }

      let maxCategory = -1;
      _.each(this.bladeFindings, finding => {
        if(finding.category > maxCategory) {
          maxCategory = finding.category;
        }
      });
      this.riskAssessment = this.Util.getDefectRecommendation(maxCategory);
    }

    getBladePosition(position) {
    	return this.bladePositionMap[position]?this.bladePositionMap[position]:position;
    }

    exportFindings() {
      this.$http({
        method: 'GET',
        url: "/api/v1/findings/export/blade/"+ this.blade._id+'?turbine='+this.blade.turbine._id,
        // This is important! Tell it to expect an arraybuffer
        responseType: "arraybuffer"
      })
      .success((data, status, headers, config) => {
        // use the saveAs library to save the buffer as a blob on the user's machine. Use the correct MIME type!
        saveAs(new Blob([data],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), this.blade.serial+'_blade.xlsx');

        const activityLogData = {
          site: this.blade.turbine.site._id,
          turbine: this.blade.turbine._id,
          blade: this.blade._id,
          user: this.currentUser._id,
          source: 'web',
          action: 'export',
          type: 'blade',
          sourceScreen: 'blade',
          eventString: `Findings for Blade <a href="/blade/${this.blade._id}">${this.bladeStr}</a> was exported in turbine <a href="/turbine/${this.blade.turbine._id}">${this.turbineStr}</a>, wind farm <a href="/site/${this.blade.turbine.site._id}">${this.blade.turbine.site.name}</a> by ${this.currentUser.name}`
        }

        this.ActivityLog.save(activityLogData).$promise
          .then(activityLog => {
            console.log(activityLog);
          })
          .catch(err => {
            console.log('Error inserting activityLog', err);
        });

      })
      .error((data, status, headers, config) => {
        console.log(data);
      });
    }

    exportRepairs() {
      this.$http({
        method: 'GET',
        url: "/api/v1/repairs/export/blade/"+ this.blade._id+'?turbine='+this.blade.turbine._id,
        // This is important! Tell it to expect an arraybuffer
        responseType: "arraybuffer"
      })
      .success((data, status, headers, config) => {
        // use the saveAs library to save the buffer as a blob on the user's machine. Use the correct MIME type!
        saveAs(new Blob([data],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), this.blade.serial+'_blade.xlsx');

        const activityLogData = {
          site: this.blade.turbine.site._id,
          turbine: this.blade.turbine._id,
          blade: this.blade._id,
          user: this.currentUser._id,
          source: 'web',
          action: 'export',
          type: 'blade',
          sourceScreen: 'blade',
          eventString: `Repairs for Blade <a href="/blade/${this.blade._id}">${this.bladeStr}</a> was exported in turbine <a href="/turbine/${this.blade.turbine._id}">${this.turbineStr}</a>, wind farm <a href="/site/${this.blade.turbine.site._id}">${this.blade.turbine.site.name}</a> by ${this.currentUser.name}`
        }

        this.ActivityLog.save(activityLogData).$promise
          .then(activityLog => {
            console.log(activityLog);
          })
          .catch(err => {
            console.log('Error inserting activityLog', err);
        });

      })
      .error((data, status, headers, config) => {
        console.log(data);
      });
    }

    openFullImage(url) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/view-full-image/viewFullImageModal.html',
        controller: 'ViewFullImageModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          imageUrl: function () { return url }
        }
      });
      modalInstance.result.then(() => {
      });
    }

    edit() {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/blade/addEditBladeModal/addEditBladeModal.html',
        controller: 'AddEditBladeModalCtrl',
        controllerAs: 'aEBlade',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Edit Blade',
            blade: this.blade,
            turbine: this.blade.turbine
          }
        }
      });
      modalInstance.result.then();
    }

    print() {
      window.print();
      const activityLogData = {
        site: this.blade.turbine.site._id,
        turbine: this.blade.turbine._id,
        blade: this.blade._id,
        user: this.currentUser._id,
        source: 'web',
        action: 'print',
        type: 'blade',
        sourceScreen: 'blade',
        eventString: `Blade <a href="/blade/${this.blade._id}">${this.bladeStr}</a> was printed in turbine <a href="/turbine/${this.blade.turbine._id}">${this.turbineStr}</a>, wind farm <a href="/site/${this.blade.turbine.site._id}">${this.blade.turbine.site.name}</a> by ${this.currentUser.name}`
      }

      this.ActivityLog.save(activityLogData).$promise
        .then(activityLog => {
          console.log(activityLog);
        })
        .catch(err => {
          console.log('Error inserting activityLog', err);
      });
    }

    shareUrl() {
      const shareUrlData = {
        url: `blade/${this.blade._id}`,
        level: 'blade',
        organization: this.currentUser.organization._id,
      }
      var url = window.location.href;
      var urlArray = url.split("/");
      var baseUrl = urlArray[0] + "//" + urlArray[2];
      this.SharedUrl.save(shareUrlData).$promise
        .then(sharedUrlResponse => {
          this.urlShared = `${baseUrl}/shared/${sharedUrlResponse.data._id}`;
          this.htmlPopover = this.$sce.trustAsHtml(`${this.urlShared} <i>Users without an account will see a read-only version of this page.</i>`);
          this.showShareUrlPopup = true;
          var dummyElement = document.createElement("textarea");
          document.body.appendChild(dummyElement);
          dummyElement.value = this.urlShared;
          dummyElement.select();
          document.execCommand("copy");
          document.body.removeChild(dummyElement);
          this.toastr.success('Link copied successfully.');
        })
        .catch(err => {
          console.log('Error inserting sharedUrl', err);
      });
    }
  }
  angular.module('windmanagerApp')
    .controller('ViewBladeCtrl', ViewBladeCtrl);
})();

